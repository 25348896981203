export default {
  create: {
    button: 'Organisation erstellen',
    title: 'Neue Organisation erstellen',
    description: 'Erstellen Sie eine neue Organisation, die Zugriff auf das Elterndashboard hat.',
  },
  form: {
    name: 'Name',
    slug: 'Abkürzung',
  },
  dangerZone: {
    title: 'Gefahrenzone',
    delete: {
      title: 'Organisation löschen',
      description:
        'Möchten Sie diese Organisation wirklich löschen? Alle Mitglieder und Daten werden unwiderruflich gelöscht.',
      confirmationDescription:
        'Bitte gib den Namen der Organisation ein, um die Löschung zu bestätigen.',
      confirm: 'Organisation löschen',
      cancel: 'Abbrechen',
    },
  },
  members: {
    title: 'Mitglieder',
    add: 'Mitglied hinzufügen',
    filter: 'Mitglieder filtern',
  },
  tabs: {
    general: 'Allgemein',
    members: 'Mitglieder',
    integrations: 'Integrationen',
  },
  sections: {
    general: {
      title: 'Allgemeine Informationen',
      description: 'Bearbeiten Sie die allgemeinen Informationen Ihrer Organisation.',
    },
  },
  notifications: {
    delete: {
      success: 'Organisation wurde erfolgreich gelöscht.',
      error: 'Organisation konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    },
    removeMember: {
      success: 'Mitglied wurde erfolgreich entfernt.',
      error: 'Mitglied konnte nicht entfernt werden. Bitte versuchen Sie es erneut.',
    },
    update: {
      success: 'Organisation wurde erfolgreich aktualisiert.',
      error: 'Organisation konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.',
    },
  },
};
