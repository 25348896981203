export default {
  title: 'Sicherheit',
  description: 'Verwalte Sicherheitsrelevante Einstellungen und Geräte',
  sections: {
    sessions: {
      title: 'Sitzungen',
      name: '{{browser}} auf {{os}}',
      currentSession: 'Aktuelle Sitzung',
      lastSeen: 'Zuletzt gesehen {{ago}}',
    },
  },
  revokeSession: {
    title: 'Sitzung beenden?',
    description: 'Möchtest du diese Sitzung wirklich beenden?',
    action: 'Sitzung beenden',
  },
  notifications: {
    revokeSession: {
      success: 'Sitzung wurde erfolgreich beendet.',
      error: 'Sitzung konnte nicht beendet werden. Bitte versuche es erneut',
      notFound: 'Sitzung nicht gefunden',
    },
  },
};
