import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';

import { getPublicEnv } from '~/utils/env/public-env.tsx';
import * as i18n from '~/utils/i18next/i18n';

import * as Sentry from '@sentry/remix';
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { StrictMode, startTransition, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next/client';

async function main() {
  await i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>,
    );
  });
}

Sentry.init({
  dsn: getPublicEnv('PUBLIC_SENTRY_DSN'),
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
  debug: getPublicEnv('IS_PRODUCTION'),
  environment: getPublicEnv('IS_PRODUCTION') ? 'production' : 'development',
  enabled: getPublicEnv('IS_PRODUCTION'),

  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
});

main().catch((error) => console.error(error));
