export default {
  title: 'Einrichtung',
  description: 'Richte die Anwendung erstmals ein, um sie zu verwenden.',
  form: {
    fullName: 'Vollständiger Name',
    email: 'E-Mail',
    organizationName: 'Organisationsname',
  },
  actions: {
    start: 'Einrichtung starten',
  },
  notifications: {
    success: 'Einrichtung erfolgreich abgeschlossen.',
    error: 'Einrichtung konnte nicht abgeschlossen werden',
    alreadySetup: 'Die Anwendung wurde bereits eingerichtet.',
  },
};
