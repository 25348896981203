export default {
  title: 'Integrationen',
  description: 'Verwalte und erstelle Integrationen für deine Anwendung',
  search: 'Integrationen suchen',
  create: {
    title: 'Neue Integration erstellen',
    description: 'Erstelle eine neue Integration für deine Anwendung',
    action: 'Integration erstellen',
  },
  form: {
    name: 'Name',
    description: 'Beschreibung',
  },
  notifications: {
    create: {
      success: 'Integration erfolgreich erstellt',
      error: 'Fehler beim Erstellen der Integration',
    },
    edit: {
      success: 'Integration erfolgreich bearbeitet',
      error: 'Fehler beim Bearbeiten der Integration',
    },
    delete: {
      success: 'Integration erfolgreich gelöscht',
      error: 'Fehler beim Löschen der Integration',
    },
    createApiKey: {
      success: 'API-Schlüssel erfolgreich erstellt',
      error: 'Fehler beim Erstellen des API-Schlüssels',
    },
    deleteApiKey: {
      success: 'API-Schlüssel erfolgreich gelöscht',
      error: 'Fehler beim Löschen des API-Schlüssels',
    },
  },
  errors: {
    notFound: 'Integration nicht gefunden.',
  },
  sections: {
    general: {
      title: 'Allgemeine Informationen',
      description: 'Allgemeine Informationen zur Integration',
    },
    apiKeys: {
      title: 'API Schlüssel',
      description: 'Verwalte die API Schlüssel der Integration',
    },
    dangerZone: {
      title: 'Gefahrenbereich',
    },
  },
  apiKey: {
    create: {
      title: 'API Schlüssel erstellen',
      description: 'Erstelle einen neuen API Schlüssel für die Integration',
      action: 'API Schlüssel erstellen',
    },
    form: {
      name: 'Name',
    },
  },
  delete: {
    title: 'Integration löschen',
    description:
      'Lösche die Integration. Damit wird auch der Zugriff auf alle API-Schlüssel entfernt.',
    action: 'Integration löschen',
  },
};
