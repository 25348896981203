import adminNavigation from '~/lib/locale/de/admin-navigation.ts';
import application from '~/lib/locale/de/application.ts';
import de from '~/lib/locale/de/de.ts';
import feedback from '~/lib/locale/de/feedback.ts';
import formBuilder from '~/lib/locale/de/form-builder.ts';
import inbox from '~/lib/locale/de/inbox.ts';
import integration from '~/lib/locale/de/integration.ts';
import managementNavigation from '~/lib/locale/de/management-navigation.ts';
import organization from '~/lib/locale/de/organization.ts';
import profile from '~/lib/locale/de/profile.ts';
import role from '~/lib/locale/de/role.ts';
import security from '~/lib/locale/de/security.ts';
import ui from '~/lib/locale/de/ui.ts';
import user from '~/lib/locale/de/user.ts';
import setup from '~/lib/locale/de/setup.ts';

export const supportedLngs = ['de', 'en'];

export const fallbackLng = 'de';

export const defaultNS = 'common';

export const resources = {
  de: {
    common: de,
    adminNavigation: adminNavigation,
    organization: organization,
    inbox: inbox,
    managementNavigation: managementNavigation,
    role: role,
    integration: integration,
    security: security,
    formBuilder: formBuilder,
    setup: setup,
    profile: profile,
    user: user,
    ui: ui,
    feedback: feedback,
    application: application,
  },
  en: {
    common: {},
  },
};
