export default {
  title: 'Rollen',
  description: 'Verwalten globale Rollen, die in allen Organisationen verwendet werden.',
  search: 'Rollen suchen',
  create: {
    action: 'Rolle erstellen',
    title: 'Neue Rolle erstellen',
    description:
      'Erstellen Sie eine neue Rolle, die in allen Organisationen verwendet werden kann.',
  },
  edit: {
    title: 'Rolle bearbeiten',
    description: 'Bearbeiten Sie die Rolle und aktualisieren Sie ihre Einstellungen.',
    action: 'Rolle aktualisieren',
  },
  delete: {
    action: "Rolle löschen",
  },
  notifications: {
    delete: {
      notDeletable: 'Die Rolle kann nicht gelöscht werden, da sie nicht als löschbar markiert ist.',
      error: 'Die Rolle konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
      success: 'Die Rolle wurde erfolgreich gelöscht.',
    },
    create: {
      success: 'Die Rolle wurde erfolgreich erstellt.',
      error: 'Die Rolle konnte nicht erstellt werden. Bitte versuchen Sie es erneut.',
    },
    update: {
      success: 'Die Rolle wurde erfolgreich aktualisiert.',
      error: 'Die Rolle konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.',
    },
  },
  dropdown: {
    edit: 'Rolle bearbeiten',
    delete: 'Rolle löschen',
  },
  tags: {
    deletable: 'Löschbar',
    hidden: 'Versteckt',
    system: 'System',
  },
  form: {
    name: 'Name',
    description: 'Beschreibung',
    organization: 'Organisation',
    permissions: 'Berechtigungen',
    workspaces: 'Arbeitsbereiche',
    deletable: 'Kann gelöscht werden',
    hidden: 'Versteckt',
    system: 'System',
  },
  placeholders: {
    description: 'Beschreiben Sie die Rolle',
    organization: 'Organisation auswählen',
    permissions: 'Berechtigungen auswählen',
    workspaces: 'Arbeitsbereiche auswählen',
  },
};
