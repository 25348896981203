export default {
  calendar: {
    months: {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember',
    },
    date: 'Datum',
    time: 'Zeit',
  },
  button: {
    confirm: 'Aktion bestätigen?',
    holdToConfirm: 'Halte zum Bestätigen',
    releaseToConfirm: 'Loslassen zum Bestätigen',
    back: 'Zurück',
    cancel: 'Abbrechen',
  },
  error: {
    help: {
      title: 'Benötigst du Hilfe?',
      description:
        'Wir helfen dir gerne weiter. Bitte hinterlasse uns etwas Feedback, damit wir dir helfen können.',
    },
    unknown: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    unknownStatus: 'Unbekannter Status',
    notFound: 'Diese Seite konnte nicht gefunden werden.',
    internalServerError: 'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
  },
  fileUpload: {
    title: 'Wähle Dateien aus oder ziehe sie hierher',
  },
};
