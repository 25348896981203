export default {
  upload: {
    notifications: {
      complete: {
        success: 'Upload erfolgreich abgeschlossen',
        error: 'Fehler beim Hochladen der Datei',
      },
    },
  },
  actions: {
    cancel: 'Abbrechen',
    create: 'Hinzufügen',
    discard: 'Verwerfen',
    close: 'Schließen',
    remove: 'Entfernen',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    past: {
      created: 'Erstellt',
      deleted: 'Gelöscht',
      updated: 'Aktualisiert',
    },
    reset: 'Zurücksetzen',
    save: 'Speichern',
    update: 'Aktualisieren',
  },
  ui: {
    selection: {
      currentCount: '{{count}} ausgewählt',
      selectAll: 'Alle auswählen',
    },
    placeholders: {
      search: 'Suchen...',
      empty: 'Keine Ergebnisse',
    },
  },
  notifications: {
    title: 'Benachrichtigungen',
    description:
      'Wähle aus, welche Benachrichtigungen an deine E-Mail {{email}} gesendet werden sollen',
    sections: {
      changeRequests: {
        title: 'Stammdaten-Änderungsanträge',
        description:
          'Erhalte Benachrichtigungen über die Aktivität bei Stammdaten-Änderungsanträgen',
      },
      feedback: {
        title: 'Feedback',
        description: 'Erhalte Benachrichtigungen über die Aktivität deines abgegebenen Feedbacks',
      },
    },
    notifications: {
      update: {
        success: 'Benachrichtigungseinstellungen erfolgreich aktualisiert',
      },
    },
  },

  userImport: {
    title: 'Benutzerimport',
    description: 'Verwalte den Import von Benutzern aus deiner Stammdatenbank',
    errors: {
      invalidImportSettings: 'Ungültige Importeinstellungen',
    },
    notifications: {
      import: {
        start: {
          success: 'Import erfolgreich gestartet',
          error: 'Import konnte nicht gestartet werden',
        },
      },
      importRule: {
        create: {
          success: 'Regel erfolgreich hinzugefügt',
          error: 'Regel konnte nicht hinzugefügt werden',
        },
        delete: {
          success: 'Regel erfolgreich gelöscht',
          error: 'Regel konnte nicht gelöscht werden',
        },
        update: {
          success: 'Regel erfolgreich aktualisiert',
          error: 'Regel konnte nicht aktualisiert werden',
        },
      },
      settings: {
        update: {
          success: 'Einstellungen erfolgreich aktualisiert',
          error: 'Einstellungen konnten nicht aktualisiert werden',
        },
      },
    },

    sections: {
      settings: {
        title: 'Einstellungen',
        description:
          'Definiere Einstellungen für den Import von Benutzern aus deiner Stammdatenbank',
        defaultRole: {
          title: 'Standardrolle',
          description:
            'Wähle die Standardrolle, die Benutzer erhalten, die aus deiner Stammdatenbank importiert werden',
          placeholder: 'Rolle auswählen',
        },
        autoImport: {
          title: 'Automatischer Import',
          description:
            'Sobald neue Benutzer in deiner Stammdatenbank erstellt werden, werden sie automatisch importiert',
        },
      },
      manualImport: {
        actions: {
          open: 'Manueller Import',
          create: 'Import erstellen',
        },
        options: {
          selectPersons: {
            title: 'Personen auswählen',
            description: 'Wähle manuell Personen aus, die importiert werden sollen',
          },
          ruleImport: {
            title: 'Regelbasiert importieren',
            description: 'Importiere Personen basierend auf definierten Regeln',
          },
        },
      },
      importRules: {
        title: 'Regeln',
        description: 'Definiere Regeln, wonach Benutzer aus der Stammdatenbank importiert werden',
        empty: {
          title: 'Keine Regeln',
          description: 'Du hast noch keine Regeln definiert.',
        },
        rule: {
          actions: {
            add: 'Regel hinzufügen',
            save: 'Regel speichern',
          },
          add: {
            title: 'Regel hinzufügen',
            description:
              'Füge eine neue Importregel hinzu, nach welcher Benutzer aus deiner Stammdatenbank importiert werden',
          },
          edit: {
            title: 'Regel bearbeiten',
            description: 'Bearbeite eine bestehende Importregel',
          },
          form: {
            name: 'Regelname',
            groupIds: 'Benötigte Gruppen',
          },
          placeholder: {
            name: "Elternimport - Gruppe 'Eltern'",
          },
        },
      },
      imports: {
        title: 'Vergangene Importe',
        description: 'Hier siehst du die letzten 5 vergangenen Importe aus deiner Stammdatenbank',
        manualImport: {
          actions: {
            start: 'Import starten',
          },
        },
        empty: {
          title: 'Keine Importe',
          description: 'Es wurden noch keine Importe durchgeführt.',
        },
        successfulImports: '{{count}} erfolgreiche Importe',
        failedImports: '{{count}} fehlgeschlagene Importe',
      },
      import: {
        title: 'Import starten',
        description: 'Starte einen neuen Import aus deiner Stammdatenbank',
        form: {
          persons: 'Personen',
          ignoreGroupRules: 'Gruppenregeln ignorieren',
        },
      },
    },
  },

  feedback: {
    details: {
      title: 'Feedback #{{id}}',
      description: 'Hier kannst du Details zum Feedback einsehen',
    },

    title: 'Feedback',
    description: 'Hier siehst du sämtliches Feedback, was jemals abgegeben wurde',
    notifications: {
      update: {
        success: 'Feedback erfolgreich aktualisiert',
      },
      create: {
        success: 'Feedback erfolgreich gesendet',
      },
      comment: {
        create: {
          success: 'Kommentar erfolgreich erstellt',
        },
        update: {
          success: 'Kommentar erfolgreich aktualisiert',
        },
        delete: {
          success: 'Kommentar erfolgreich gelöscht',
        },
      },
    },
    sections: {
      open: {
        title: 'Ausstehendes Feedback',
      },
      closed: {
        title: 'Abgeschlossenes Feedback',
      },
      comments: {
        title: 'Kommentare',
        description: 'Hier kannst du Kommentare zum Feedback hinterlassen',
      },
    },
    buttons: {
      open: 'Feedback',
      submit: 'Feedback senden',
    },
    dialog: {
      title: 'Feedback abgeben',
      description:
        'Unzufrieden? Auf einen Fehler gestoßen? Einen Geistesblitz? Wir freuen uns über jedes Feedback!',
      changeStatus: {
        title: 'Feedback - Status ändern',
        description: 'Ändere hier den Status des Feedbacks',
      },
    },
    fields: {
      more: 'Was können wir besser machen? (Optional)',
    },
    placeholders: {
      more: 'Dein Feedback...',
    },
    createdAfterError: 'Fehler',
    cause: {
      error: 'Fehler aufgetreten',
      improvement: 'Verbesserungsvorschlag',
      question: 'Fragen zur Anwendung',
      idea: 'Ideen zur Anwendung',
    },
    status: {
      triage: 'Triage',
      open: 'Offen',
      resolved: 'Abgeschlossen',
      closed: 'Geschlossen',
    },
  },
  affirmations: {
    no: 'Nein',
    yes: 'Ja',
  },
  family: {
    members: {
      title: 'Familienmitglieder',
      description: 'Hier kannst du die Mitglieder deiner Familie ansehen und bearbeiten',
    },
  },
  comment: {
    actions: {
      create: 'Kommentar hinzufügen',
    },
    notifications: {
      create: {
        fail: 'Fehler beim Erstellen des Kommentars',
        success: 'Kommentar erfolgreich erstellt',
      },
      delete: {
        fail: 'Fehler beim Löschen des Kommentars',
        success: 'Kommentar erfolgreich gelöscht',
      },
      update: {
        fail: 'Fehler beim Aktualisieren des Kommentars',
        success: 'Kommentar erfolgreich aktualisiert',
      },
    },
  },
  addressMove: {
    title: 'Address-Umzug',
    description:
      'Hier kannst du den Umzug mehrere Familienmitglieder an eine neue Adresse bearbeiten',
    sections: {
      selectPersons: {
        title: 'Personen auswählen',
        description: 'Wähle die Personen aus, die du an die neue Adresse umziehen möchtest',
        disclaimer: 'Bitte wähle mindestens eine Person aus um fortzufahren',
      },
      selectedPersons: {
        title: 'Ausgewählte Personen',
        description: 'Folgende Personen werden an die neue Adresse umgezogen:',
      },
      enterAddressData: {
        title: 'Adressdaten eingeben',
        description: 'Gebe die Adressdaten der neuen Adresse ein',
      },
      confirmDialog: {
        title: 'Neue Adresse erstellen?',
        description:
          'Nutzen Sie diese Funktion, wenn Sie einige Personen ihrer Familie an eine neue Adresse umziehen wollen',
      },
    },
    notifications: {
      discard: {
        notSupported: 'Das Verwerfen von Daten wird bei einem Umzug nicht unterstützt',
      },
    },
    select: {
      placeholder: 'Personen auswählen',
    },
    buttons: {
      create: 'Adresse erstellen',
      viewChangeRequest: 'Änderungsantrag ansehen',
      confirm: 'Bestätigen',
    },
  },
  dataChangeRequest: {
    actions: {
      apply: 'Änderungen übernehmen',
      archive: 'Archivieren',
      close: 'Antrag verwerfen',
      edit: 'Weiter bearbeiten',
      reject: 'Antrag ablehnen',
      view: 'Ansehen',
    },
    dialog: {
      accept: {
        description: 'Die Änderungen werden in die Stammdatenbank übernommen',
        title: 'Änderungsantrag annehmen',
      },
      close: {
        description: 'Die Änderungen werden verworfen und nicht weiter übernommen.',
        title: 'Änderungsantrag verwerfen',
      },
      reject: {
        description:
          'Die Änderungen werden verworfen und könne nicht mehr in die Stammdatenbank übernommen werden',
        title: 'Änderungsantrag ablehnen',
      },
      editValue: {
        title: 'Inhalt bearbeiten',
        description: 'Hier kannst du den Inhalt dieser Änderung noch einmal überarbeiten',
        form: {
          content: 'Inhalt',
        },
        actions: {
          save: 'Speichern',
          cancel: 'Abbrechen',
        },
      },
    },
    events: {
      apply: 'hat Änderungen angewendet',
      close: 'hat den Antrag geschlossen',
      edit: 'hat Änderungen vorgenommen',
      reject: 'hat den Antrag abgelehnt',
      adjust: 'hat den Antrag verändert',
    },
    models: {
      address: {
        change: 'Adresse wechseln',
        description: 'Bearbeite die Daten einer bestehenden Adresse.',
        title: {
          plural: 'Adressen',
          singular: 'Adresse',
        },
      },
      addressMove: {
        description: 'Ziehe Teile deiner Familie an eine neue Adresse um',
        title: {
          plural: 'Adress-Umzüge',
          singular: 'Adress-Umzug',
        },
      },
      contactInformation: {
        description: 'Bearbeite die Kontaktinformationen einer Person oder Adresse',
        title: {
          plural: 'Kontaktinformationen',
          singular: 'Kontaktinformation',
        },
      },
      person: {
        change: 'Person wechseln',
        description: 'Bearbeite die Personendaten einer bestehenden Person',
        title: {
          plural: 'Personen',
          singular: 'Person',
        },
      },
    },
    notifications: {
      apply: {
        fail: 'Der Änderungsantrag konnte nicht übernommen werden',
        success: 'Änderungen erfolgreich in die Stammdatenbank übernommen',
      },
      archive: {
        fail: 'Fehler beim Archivieren der Änderungsanträge',
        success: 'Änderungsanträge erfolgreich archiviert',
      },
      close: {
        fail: 'Fehler beim schließen des Änderungsantrags',
        success: 'Änderungsantrag erfolgreich geschlossen',
      },
      reject: {
        fail: 'Fehler beim Ablehnen des Änderungsantrags',
        success: 'Änderungsantrag erfolgreich abgelehnt',
      },
      save: {
        success: 'Änderungen erfolgreich gespeichert',
      },
      edit: {
        success: 'Änderungsantrag erfolgreich bearbeitet',
      },
    },
    sections: {
      activity: {
        title: 'Aktivität',
      },
      changes: {
        description: 'Hier siehst du alle Änderungen in diesem Antrag',
        title: 'Änderungen',
      },
      closed: {
        description: 'Hier siehst du alle Änderungsanträge die bereits abgeschlossen sind',
        title: 'Abgeschlossene Änderungsanträge',
      },
      details: {
        description: 'Hier siehst du eine detaillierte Übersicht über den Änderungsantrag',
        title: 'Änderungsantrag - Details',
      },
      empty: {
        description: 'Du kannst entspannt durchatmen - hier gibt es nichts zu sehen.',
        title: 'Keine ausstehenden Änderungsanträge',
      },
      open: {
        description: 'Hier siehst du alle Änderungsanträge die aktuell offen sind',
        title: 'Offene Änderungsanträge',
      },
      overview: {
        description:
          'Hier siehst du eine Übersicht über alle Änderungsanträge, die aktuell ausstehen oder vor kurzem erledigt wurden.',
        title: 'Änderungsanträge - Übersicht',
      },
    },
    status: {
      applied: 'Angewendet',
      closed: 'Geschlossen',
      pending: 'In Bearbeitung',
      rejected: 'Abgelehnt',
      rolledBack: 'Zurückgerollt',
    },
  },
  dataChangeSteps: {
    editData: {
      description:
        'Bearbeite die Daten der ausgewählten {{model}}. Bereits getätigte Änderungen werden angezeigt.',
      title: 'Daten bearbeiten',
    },
    selectAddress: {
      description: 'Wähle eine Adresse aus, die du bearbeiten möchtest',
      required: 'Bitte wähle eine Adresse aus',
      title: 'Welche Adresse möchtest du bearbeiten?',
    },
    selectEntity: {
      description: 'Wähle eine {{model}} aus, die du bearbeiten möchtest',
      title: 'Welche {{model}} möchtest du bearbeiten?',
    },
    selectModel: {
      description: 'Wähle aus, welche Informationen du bearbeiten möchtest',
      title: 'Welche Informationen möchtest du bearbeiten?',
    },
    selectPerson: {
      description: 'Wähle eine Person aus, die du bearbeiten möchtest',
      required: 'Bitte wähle eine Person aus',
      title: 'Welche Person möchtest du bearbeiten?',
    },
  },
  datePicker: {
    select: 'Datum auswählen',
    today: 'Heute',
  },
  delete: 'Löschen',
  edit: 'Bearbeiten',
  emailSendSuccess: 'Die E-Mail wurde erfolgreich gesendet',
  emptyValue: 'Kein Wert',
  error: {
    description: 'Bei ihrer Anfrage ist ein Fehler aufgetreten.',
    options: {
      home: 'Zur Startseite',
      reload: 'Neu laden',
    },
    title: 'Es ist ein Fehler aufgetreten',
  },
  errors: {
    personLoadFailure: {
      title: 'Personendaten konnten nicht geladen werden',
      description:
        'Ihre Personendaten konnten nicht geladen werden. Bitte versuchen Sie es später erneut.',
    },
    addressMove: {
      validation: {
        invalidSelectedPersons: 'Ungültige Anzahl an ausgewählten Personen',
      },
    },
    address: {
      notFound: 'Diese Adresse existiert nicht',
    },
    dataChange: {
      missingPersonsToMove: 'Keine Personen zum umziehen gefunden',
      noChangesDetected: 'Es wurden keine Änderungen durchgeführt',
      notFound: 'Dieser Änderungsantrag existiert nicht',
      unsupportedModel: 'Dieser Datentyp wird nicht unterstützt',
    },
    dataSourceProvider: {
      loadFailure: 'Daten konnten nicht geladen werden',
    },
    invalidRequest: 'Ungültige Anfrage',
    notFound: 'Die angefragten Daten konnten nicht gefunden werden',
    organization: {
      notFound: 'Organisation nicht gefunden',
    },
    role: {
      notFound: 'Rolle nicht gefunden',
    },
    session: {
      notFound: 'Sitzung nicht gefunden',
    },
    unauthorized: 'Fehlende Berechtigung',
    user: {
      noOrganizations: 'Dieser Benutzer ist in keiner Organisation',
      notFound: 'Benutzer nicht gefunden',
      organizationMembershipNotFound:
        'Dieser Benutzer konnte keiner Person in der Organisation zugeordnet werden',
      selfDeactivate: 'Du kannst dich nicht selbst deaktivieren',
      selfDelete: 'Du kannst dich nicht selbst löschen',
    },
  },
  existingDataChange: {
    description: 'Für diese {{model}} stehen noch Änderungen aus',
    title: 'Ausstehende Änderungen',
    view: 'Ausstehende Änderungen ansehen',
  },
  false: 'Falsch',
  filter: {
    title: 'Einträge filtern...',
  },
  formBuilder: {
    addBlock: 'Block hinzufügen',
    addStep: 'Schritt hinzufügen',
    blockDescription: 'Blockbeschreibung',
    blockName: 'Blockname',
    blockType: 'Blocktyp',
    blockTypeOptions: {
      CHECKBOX: 'Checkbox',
      DATE: 'Datum',
      EMAIL: 'E-Mail Adresse',
      FILE: 'Datei',
      NUMERIC: 'Numerisch',
      PHONE: 'Telefon (alphanumerisch)',
      RADIOSELECT: 'Radiobuttons',
      SELECT: 'Auswahl',
      TEXT: 'Text',
      TEXTBOX: 'Text (mehrzeilig)',
      ZIP: 'Postleitzahl',
    },
    duplicateBlock: 'Block duplizieren',
    duplicateStep: 'Schritt duplizieren',
    noOptions: 'Keine Optionen',
    optionAlreadyExists: 'Option existiert bereits',
    optionName: 'Optionen',
    optionRequired: 'Optionen sind erforderlich',
    removeBlock: 'Block entfernen',
    removeStep: 'Schritt entfernen',
    saveBlock: 'Block speichern',
    stepName: 'Schrittname',
  },
  formValidationError: 'Bitte überprüfen Sie Ihre Eingaben',
  forms: {
    checkbox: 'Auswählen',
    create: 'Formular erstellen',
    deleteSuccess: 'Formular erfolgreich gelöscht',
    description: 'Verwalte alle Formulare, die für die Online-Anmeldung verfügbar sind',
    dropdown: {
      delete: 'Löschen',
      duplicate: 'Duplizieren',
      edit: 'Bearbeiten',
      settings: 'Einstellungen',
    },
    duplicateSuccess: 'Formular erfolgreich dupliziert',
    empty: {
      description:
        'Es wurden keine Formulare gefunden. Erstelle ein neues Formular, um zu beginnen.',
      title: 'Keine Formulare gefunden',
    },
    invisible: 'Unsichtbar',
    labels: {
      description: 'Beschreibung',
      name: 'Name',
      visibleFrom: 'Sichtbar von',
      visibleUntil: 'Sichtbar bis',
    },
    required: 'Erforderlich',
    saveError: 'Fehler beim Speichern des Formulars',
    search: 'Suche...',
    shared: 'Geteilt',
    title: 'Formulare',
    visible: 'Sichtbar',
  },
  genders: {
    female: 'Weiblich',
    male: 'Männlich',
    other: 'Divers',
  },
  home: {
    greeting: {
      description:
        'Herzlich Willkommen auf dem Elterndashboard der {{organization}}. Hier kannst du deine Stammdaten einsehen und auf dem aktuellsten Stand halten.',
      title: 'Herzlich Wilkommen 👋',
    },
  },
  admin: {
    title: 'Administration',
  },
  inbox: {
    title: 'Meine Vorgänge',
  },
  impersonation: {
    quit: 'Beenden',
    title: 'Du arbeitest als {{username}}',
  },
  integrations: {
    activeTitle: 'Aktive Integrationen',
    addIntegrationSuccess: 'Integration erfolgreich hinzugefügt',
    description: 'Verwalte Integrationen',
    empty: 'Keine Integrationen gefunden',
    inactiveTitle: 'Inaktive Integrationen',
    removeIntegrationSuccess: 'Integration erfolgreich entfernt',
    title: 'Integrationen',
  },
  invalidColor: 'Ungültige Farbe',
  list: {
    empty: 'Keine Einträge in dieser Liste',
    pageCount: 'Seite {{currentPage}} von {{totalPages}}',
  },
  login: {
    button: 'E-Mail senden',
    description:
      'Bitte melde dich mit deiner E-Mail Adresse oder einer anderen Anmeldungsmethode an',
    disclaimer:
      'Mit der Anmeldung stimmen Sie den Nutzungsbedingungen und Datenschutzrichtlinien der Triargos GmbH bereit.',
    form: {
      email: {
        label: 'E-Mail',
        placeholder: 'john@acme.inc',
      },
    },
    validation: {
      invalidParameters: 'Ungültige Parameter. Bitte versuchen Sie es erneut.',
      missingInteractionId: 'Interaction-ID fehlt. Bitte versuchen Sie es erneut.',
      noMagicLinks:
        'Keine Anmeldungen für diesen Benutzer verfügbar. Bitte versuchen Sie es erneut',
      missingToken: 'Token fehlt. Bitte versuchen Sie es erneut.',
      invalidLink: 'Ungültiger Link. Bitte versuchen Sie es erneut',
      authIdMismatch: 'Dieser Link passt nicht zur Anmeldung. Bitte versuchen Sie es erneut',
      userNotFound: 'Benutzer nicht gefunden',
    },

    imprint: 'Impressum',
    sent: {
      description:
        'Wir haben eine E-Mail mit einem Anmeldungslink an deine E-Mail Adresse {{email}} versendet. Bitte fahre mit diesem Link fort.',
      title: 'Überprüfe dein Postfach',
    },
    title: 'Willkommen zurück',
  },
  logout: 'Abmelden',
  members: {
    description: 'Verwalte die Mitglieder dieser Organisation',
    remove: 'Entfernen',
    select: 'Wähle ein Mitglied aus',
    title: 'Mitglieder',
  },
  models: {
    address: {
      dialogs: {
        select: {
          title: 'Adresse auswählen',
          description: 'Wähle die Adresse aus, die du bearbeiten möchtest',
        },
        create: {
          title: 'Neue Adresse hinzufügen',
          description: 'Ziehe Teile deiner Familie an eine neue Adresse um',
        },
      },
      fields: {
        additional: 'Zusätzliche Angaben',
        city: 'Stadt / Ort',
        country: 'Land',
        county: 'Landkreis',
        district: 'Ortsteil',
        poBox: 'Postfach',
        poBoxZip: 'Postfach-PLZ',
        singleMove: 'Nur mich umziehen',
        street: 'Straße',
        zip: 'Postleitzahl',
      },
      placeholders: {
        city: 'Musterstadt',
        district: 'Musterhausen',
        street: 'Musterstraße 123',
        zip: '82982',
      },
      sections: {
        additional: {
          description: 'Zusätzliche Adressdaten',
          title: 'Zusätzliche Adressdaten',
        },
        poBox: {
          description: 'Daten zum Postfach (optional)',
          title: 'Postfach',
        },
        primary: {
          description: 'Primäre Adressdaten',
          title: 'Adressdaten',
        },
      },
    },
    contactInformation: {
      dialogs: {
        create: {
          description: 'Hier kannst du neue Kontaktdaten hinzufügen',
          title: 'Kontaktinformationen hinzufügen',
        },
        edit: {
          description: 'Hier kannst du bereits existierende Kontaktdaten bearbeiten',
          title: 'Kontaktinformationen bearbeiten',
        },
      },
      fields: {
        content: 'Inhalt',
        secret: 'Geheim',
        type: 'Typ (Beruflich / Privat',
      },
      mediums: {
        email: {
          plural: 'E-Mails',
          singular: 'E-Mail',
        },
        mobile: {
          plural: 'Mobilnummern',
          singular: 'Mobilnummer',
        },
        telephone: {
          plural: 'Festnetznummern',
          singular: 'Festnetz',
        },
        fax: {
          plural: 'Faxnummern',
          singular: 'Fax',
        },
      },
      tags: {
        new: 'Neu hinzugefügt',
        secret: 'Geheim',
        visible: 'Sichtbar',
        work: 'Beruflich',
      },
      types: {
        address: 'Adresse',
        external: 'Extern',
        private: 'Privat',
        work: 'Beruflich',
      },
      changeTypes: {
        created: 'Erstellte Kontaktinformationen',
        deleted: 'Gelöschte Kontaktinformationen',
        updated: 'Aktualisierte Kontaktinformationen',
      },
      empty: {
        title: 'Hier gibt es nichts zu sehen',
        description: 'Hier existieren noch keine Kontaktinformationen',
      },
    },
    country: {
      actions: {
        select: 'Land auswählen',
      },
      empty: 'Kein Land gefunden',
    },
    county: {
      actions: {
        select: 'Landkreis auswählen',
      },
      empty: 'Kein Landkreis gefunden',
    },
    person: {
      dialogs: {
        select: {
          title: 'Person auswählen',
          description: 'Wähle die Person aus, die du bearbeiten möchtest',
        },
      },
      birthDate: {
        empty: 'Kein Geburtsdatum',
      },
      familyRole: {
        father: 'Vater',
        mother: 'Mutter',
        child: 'Kind',
      },
    },
  },
  myDataChanges: {
    description: 'Hier kannst du deinen Verlauf an Stammdatenänderungen ansehen.',
    sections: {
      closedChangeRequests: {
        description:
          'Hier siehst du alle Änderungen die in der Vergangenheit angewendet bzw. abgelehnt wurden',
        title: 'Vergangene Änderungen',
      },
      openChangeRequests: {
        description:
          'Hier siehst du alle Änderungen an deinen Stammdaten, die noch nicht angewendet wurden',
        title: 'Ausstehende Änderungen',
      },
    },
    title: 'Meine Stammdatenänderungen',
  },
  next: 'Weiter',
  openDataChanges: {
    description: 'Hier siehst du eine übersicht über aktuelle Änderungsanträge',
    remainingCount: '{{count}} verbleibend',
    title: 'Ausstehende Stammdatenänderungen',
  },
  //New org
  organization: {
    tabs: {
      general: 'Allgemeine Informationen',
      members: 'Mitglieder',
      integrations: 'Integrationen',
      danger: 'Gefahrenbereich',
    },
    sections: {
      branding: {
        form: {
          lightPanelColors: 'Panelfarben (hell)',
          darkPanelColors: 'Panelfarben (dunkel)',
          panelImages: 'Panelbilder',
        },
        panelColors: {
          dialog: {
            title: 'Neue Panelfarbe hinzufügen',
            description:
              'Wähle eine neue Panelfarbe aus, die den Benutzern zufällig im Dashboard angezeigt wird. Wenn nur eine Farbe ausgewählt ist, ändert sich diese nicht.',
            action: 'Farbe hinzufügen',
          },
        },
      },
    },
    notifications: {
      branding: {
        update: {
          success: 'Erscheinungsbild der Organisation aktualisiert',
          error: 'Fehler beim aktualisieren des Erscheinungsbildes',
        },
      },
    },
  },
  //New user
  user: {
    sections: {
      organizations: {
        title: 'Organisationen',
        description: 'Hier kannst du die Organisationen des Benutzers ansehen und bearbeiten',
        search: 'Organisationen suchen...',
        empty: 'Keine Organisationen',
        addOrganization: 'Organisation hinzufügen',
      },
      roles: {
        title: 'Rollen',
        description: 'Hier kannst du die Rollen des Benutzers ansehen und bearbeiten',
        addRole: 'Rolle hinzufügen',
        search: 'Rollen suchen...',
        empty: 'Keine Rollen',
      },
      activation: {
        title: 'Benutzer aktivieren / deaktivieren',
        description:
          'Aktiviere oder deaktiviere den Benutzer. Deaktivierte Benutzer erhalten keinen Zugriff auf das Elterndashboard',
        activateUser: 'Benutzer aktivieren',
        deactivateUser: 'Benutzer deaktivieren',
      },
      deletion: {
        title: 'Benutzer löschen',
        description:
          'Dadurch wird der Benutzer endgültig gelöscht und verliert Zugriff auf das Elterndashboard.',
        deleteUser: 'Benutzer löschen',
      },
      features: {
        title: 'Features',
        save: 'Speichern',
        description:
          'Weise dem Benutzer Feature-Flags zu, um Zugriff auf neue Funktionen zu erhalten, die noch nicht für die Allgemeinheit freigeschalten sind',
      },
    },

    notifications: {
      update: {
        success: 'Benutzer erfolgreich aktualisiert',
        error: 'Benutzer konnte nicht aktualisiert werden',
      },
      delete: {
        success: 'Benutzer erfolgreich gelöscht',
        error: 'Benutzer konnte nicht gelöscht werden',
      },
      connectRole: {
        success: 'Rolle erfolgreich dem Benutzer zugewiesen',
        error: 'Fehler beim Zuweisen der Rolle',
      },
      disconnectRole: {
        success: 'Rolle erfolgreich vom Benutzer entfernt',
        error: 'Fehler beim Entfernen der Rolle',
      },
      addToOrganization: {
        success: 'Benutzer erfolgreich zur Organisation hinzugefügt',
        error: 'Fehler beim Hinzufügen zur Organisation',
      },
      removeFromOrganization: {
        success: 'Benutzer erfolgreich aus der Organisation entfernt',
        error: 'Fehler beim Entfernen aus der Organisation',
      },
      featureFlagUpdate: {
        success: 'Feature-Flags des Benutzers erfolgreich aktualisiert',
        error: 'Fehler beim aktualisieren der Feature-Flags',
      },
    },
    validation: {
      selfDeactivation: 'Du kannst dich nicht selbst deaktivieren',
      selfDeletion: 'Du kannst dich nicht selbst löschen',
      lastOrganization: 'Der Benutzer muss mindestens Teil einer Organisation sein',
      roleNotDeletable:
        'Du kannst diese Rolle nicht von dir entfernen, da dies die letzte administrative Rolle ist',
    },
    error: {
      notFound: 'Dieser Benutzer konnte nicht gefunden werden',
    },
  },
  role: {
    notifications: {
      create: {
        success: 'Rolle erfolgreich erstellt',
      },
      update: {
        success: 'Rolle erfolgreich aktualisiert',
        error: 'Fehler beim Aktualisieren der ROlle',
      },
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      organization: 'Organisation',
      permissions: 'Berechtigungen',
      deletable: 'Kann gelöscht werden',
      workspaces: 'Arbeitsbereiche',
    },
    placeholders: {
      description: 'Eine sinnvolle Beschreibung der Rolle',
      permissions: 'Berechtigungen auswählen',
      workspaces: 'Arbeitsbereiche auswählen',
    },
    workspaces: {
      developer: 'Entwickler',
      admin: 'Administrator',
      management: 'Verwaltung',
      app: 'Benutzer',
    },
    sections: {
      create: {
        dialog: {
          title: 'Neue Rolle erstellen',
          description: 'Erstelle eine neue Rolle um sie Benutzern zuzuweisen',
          action: 'Rolle erstellen',
        },
      },
      edit: {
        dialog: {
          title: 'Rolle bearbeiten',
          description: 'Bearbeite die bestehende Rolle',
          action: 'Speichern',
        },
      },
    },
  },

  organizations: {
    activate: {
      confirmDescription:
        'Die Organisation wird aktiviert und alle Teilnehmer erhalten Zugriff auf die Organisation.',
      confirmTitle: 'Organisation wirklich aktivieren?',
      description: 'Die Organisation wird aktiviert und der Zugriff wieder aktiviert',
      title: 'Organisation aktivieren',
    },
    active: {
      description: 'Hier siehst du alle aktiven Organisationen',
      title: 'Aktive Organisationen',
    },
    addIntegrationSuccess: 'Integration erfolgreich aktiviert',
    addMember: 'Mitglied hinzufügen',
    addMemberSuccess: 'Mitglied erfolgreich hinzugefügt',
    create: {
      description: 'Erstelle eine neue Organisation',
      title: 'Organisation erstellen',
    },
    createSuccess: 'Organisation erfolgreich erstellt',
    createdAt: 'Erstellt am {{date}}',
    deactivate: {
      confirmDescription:
        'Organisation wirklich deaktivieren? Allen Teilnehmern wird der Zugriff auf diese Organisation entzogen',
      confirmTitle: 'Organisation wirklich deaktivieren?',
      description:
        'Damit wird die Organisation deaktiviert und allen Teilnehmern der Zugriff entzogen',
      title: 'Organisation deaktivieren',
    },
    deactivated: 'Deaktiviert',
    delete: {
      confirmDescription: 'Diese Aktion kann nicht rückgängig gemacht werden',
      confirmTitle: 'Organisation wirklich löschen?',
      description: 'Die Organisation und alle damit verbundenen Daten wird unwiderruflich gelöscht',
      title: 'Organisation löschen',
    },
    deleteSuccess: 'Organisation erfolgreich gelöscht',
    description: 'Verwalte alle Organisationen in dieser Instanz.',
    empty: 'Keine Organisationen gefunden',
    form: {
      active: 'Aktiv',
      darkBackgroundColorStyle: 'Hintergrundstil',
      darkColor: 'Primärfarbe (dunkel)',
      dataSourceProvider: 'Datenquelle',
      dataSourceProviderApiKey: 'API-Schlüssel',
      dataSourceProviderEndpoint: 'Datenschnittstelle',
      lightColor: 'Primärfarbe (hell)',
      name: 'Name',
      slug: 'Abkürzung',
    },
    inactive: {
      description: 'Hier siehst du alle inaktiven Organisationen',
      title: 'Inaktive Organisationen',
    },
    removeIntegrationSuccess: 'Integration erfolgreich deaktiviert',
    removeMember: 'Mitglied entfernen',
    removeMemberSuccess: 'Mitglied erfolgreich entfernt',
    search: 'Organisation suchen',
    sections: {
      datasourceProvider: {
        description:
          'Konfiguriere die Anbindung der Organisation an die Schnittstelle ihrer Stammdatenbank',
        title: 'Datenquellenanbieter',
      },
      general: {
        description: 'Verwalte die allgemeinen Informationen der Organisation',
        title: 'Allgemeine Informationen',
      },
      integrations: 'Integrationen',
      members: 'Mitglieder',
      theme: {
        description: 'Konfiguriere die Darstellungsoptionen (Erscheinung) der Organisation',
        title: 'Darstellung',
      },
    },
    title: 'Organisationen',
    toggleActivationSuccess: 'Status der Organisation erfolgreich geändert',
    updateSuccess: 'Organisation erfolgreich aktualisiert',
  },
  person: {
    academicTitle: 'Akademischer Titel',
    allFirstNames: 'Alle Vornamen',
    birthCountry: 'Geburtsland',
    birthDate: 'Geburtsdatum',
    birthName: 'Geburtsname',
    birthPlace: 'Geburtsort',
    firstName: 'Vorname',
    gender: 'Geschlecht',
    jobTitle: 'Berufsbezeichnung',
    lastName: 'Nachname',
    nationality: 'Nationalität',
    religion: 'Religion',
  },
  personalData: {
    description: 'Ändere deine Stammdaten oder die von Personen, die du bearbeiten darfst.',
    labels: {
      academicTitle: 'Akademischer Titel',
      allFirstNames: 'Alle Vornamen',
      birthCountry: 'Geburtsland',
      birthDate: 'Geburtsdatum',
      birthName: 'Geburtsname',
      firstName: 'Vorname',
      gender: 'Geschlecht',
      jobTitle: 'Berufsbezeichnung',
      lastName: 'Nachname',
      nationality: 'Nationalität',
      placeOfBirth: 'Geburtsort',
      religion: 'Religion',
      selectCountries: 'Land auswählen',
      selectReligions: 'Religion auswählen',
    },
    sections: {
      additional: {
        description: 'Ändere zusätzliche Daten',
        title: 'Zusätzliche Daten',
      },
      emails: {
        title: 'Persönliche E-Mails',
      },
      mobile: {
        title: 'Persönliche Mobilnummern',
      },
      personal: {
        description: 'Ändere deine persönlichen Daten',
        title: 'Persönliche Daten',
      },
      phone: {
        title: 'Persönliche Festnetznummern',
      },
    },
    title: 'Meine Stammdaten',
  },
  previous: 'Zurück',
  profile: {
    description: 'Hier kannst du dein Profil ansehen und bearbeiten',
    form: {
      email: 'E-Mail',
      fullName: 'Name',
    },
    sections: {
      appearance: {
        description: 'Konfiguriere das Erscheinungsbild der Anwendung',
        options: {
          dark: 'Dunkel',
          light: 'Hell',
        },
        title: 'Erscheinungsbild',
      },
      uiPreferences: {
        title: 'Benutzeroberflächeneinstellungen',
        description: 'Konfiguriere die Oberfläche, so wie du sie am liebsten magst',
        enableCollapsibleSidebar: 'Navigationsleiste automatisch ausblenden (Desktop)',
        preferColorPanels: 'Farbflächen anstatt Bildern auf der Startseite anzeigen',
      },
      personalData: {
        description: 'Bearbeite deine persönlichen Daten im Elterndashboard',
        title: 'Persönliche Daten',
      },
    },
    title: 'Profil',
    updateSuccess: 'Profil erfolgreich aktualisiert',
  },
  registrationOverview: {
    addSubmission: 'Anmeldung hinzufügen',
    childForm: {
      counter: 'Kind {{index}}',
      title: 'Anzumeldende Kinder',
    },
    complete: 'Vollständig',
    deleteSubmission: 'Anmeldung löschen',
    description: 'Hier kannst du dich für die verschiedenen Angebote der Organisation anmelden',
    editSubmission: 'Anmeldung bearbeiten',
    finishRegistration: 'Anmeldung abschließen',
    incomplete: 'Unvollständig',
    noRegistrations: {
      description: 'Es sind keine öffentlichen Anmeldungen verfügbar',
      title: 'Keine öffentlichen Anmeldungen',
    },
    parentForm: {
      counter: 'Sorgeberechtige/r {{index}}',
      title: 'Anzumeldende Sorgeberechtigte',
    },
    startRegistration: 'Anmeldung starten',
    title: 'Herzlich Willkommen bei {{organizationName}}',
  },
  registrations: {
    create: 'Anmeldung erstellen',
    createSuccess: 'Anmeldung erfolgreich erstellt',
    deleteSuccess: 'Anmeldung erfolgreich gelöscht',
    description: 'Verwalte alle Online-Anmeldungen dieser Organisation',
    dropdown: {
      delete: 'Löschen',
      duplicate: 'Duplizieren',
      edit: 'Bearbeiten',
    },
    duplicateSuccess: 'Anmeldung erfolgreich dupliziert',
    empty: {
      description:
        'Es wurden keine Anmeldungen gefunden. Erstelle eine neue Anmeldung, um zu beginnen.',
      title: 'Keine Anmeldungen gefunden',
    },
    labels: {
      active: 'Aktiv',
      childForm: 'Formular für Kinder',
      description: 'Beschreibung',
      inactive: 'Inaktiv',
      maxChildFormSubmissions: 'Maximale Anzahl an Anmeldungen für Kinder',
      maxParentFormSubmissions: 'Maximale Anzahl an Anmeldungen für Sorgeberechtigte',
      membersOnly: 'Nur für Mitglieder',
      name: 'Name',
      parentForm: 'Formular für Sorgeberechtigte',
      visibleFrom: 'Sichtbar von',
      visibleUntil: 'Sichtbar bis',
    },
    sections: {
      forms: {
        description: 'Wähle die Formulare für die Anmeldung aus',
        title: 'Formulare',
      },
      general: {
        description: 'Allgemeine Informationen zur Anmeldung',
        title: 'Allgemein',
      },
      visibility: {
        description: 'Wähle den Zeitraum, in dem die Anmeldung sichtbar sein soll',
        title: 'Sichtbarkeit',
      },
    },
    title: 'Online-Anmeldung',
  },
  roles: {
    create: {
      description: 'Erstelle eine neue Rolle',
      title: 'Rolle erstellen',
    },
    createSuccess: 'Rolle erfolgreich erstellt',
    delete: 'Rolle löschen',
    deleteFailure: 'Rolle kann nicht gelöscht werden',
    deleteSuccess: 'Rolle erfolgreich gelöscht',
    description: 'Verwalte alle Rollen',
    edit: {
      description: 'Bearbeite Informationen und Berechtigungen der Rolle',
      title: 'Rolle bearbeiten',
    },
    empty: 'Keine Rollen',
    form: {
      deletable: 'Löschbar',
      name: 'Name',
      organization: 'Organisation',
      workspaces: 'Arbeitsbereiche',
    },
    general: {
      description: 'Ändere den Namen und die Organisation der Rolle',
      title: 'Generelle Informationen',
    },
    global: {
      description: 'Globale Rollen sind für alle Organisationen verfügbar',
      title: 'Globale Rollen',
    },
    noOrganization: 'Keine Organisation',
    organization: {
      description: 'Organisationsrollen sind nur für die ausgewählte Organisation verfügbar',
      title: 'Organisationsrollen',
    },
    permissions: {
      actions: {
        create: 'Erstellen',
        delete: 'Löschen',
        read: 'Lesen',
        update: 'Aktualisieren',
      },
      description: 'Ändere die Berechtigungen der Rolle',
      entities: {
        apiKey: 'API-Schlüssel',
        comment: 'Kommentare',
        dataChange: 'Änderungsantrag',
        datasource: 'Datenquelle',
        form: 'Formular',
        integration: 'Integration',
        invite: 'Einladung',
        organization: 'Organisation',
        organizationMember: 'Organisationsmitglied',
        registration: 'Online-Anmeldung',
        role: 'Rolle',
        team: 'Team',
        user: 'Benutzer',
      },
      title: 'Berechtigungen',
      updateSuccess: 'Berechtigungen erfolgreich aktualisiert',
    },
    selectOrganization: 'Organisation auswählen',
    title: 'Rollen',
    updateSuccess: 'Rolle erfolgreich aktualisiert',
  },
  save: 'Speichern',
  security: {
    description: 'Hier kannst du deine Sicherheitseinstellungen verwalten',
    sessions: {
      currentSession: 'Aktuelle Sitzung',
      description: 'Hier kannst du alle Sitzungen einsehen und beenden',
      lastSeen: 'Zuletzt vor {{ago}} gesehen',
      name: '{{browser}} auf {{os}}',
      revoke: {
        description:
          'Damit wird die Sitzung beendet. Das Gerät mit dieser Sitzung muss sich neu anmelden',
        title: 'Sitzung beenden',
      },
      revokeSuccess: 'Sitzung erfolgreich beendet',
      title: 'Sitzungen',
    },
    title: 'Sicherheit',
  },
  sendEmail: 'E-Mail senden',
  settings: {
    description: 'Ändere deine Einstellungen',
    title: 'Einstellungen',
  },
  sidebar: {
    _groups: {
      app: 'Anwendung',
      data: 'Meine Daten',
    },
    dashboard: 'Dashboard',
    dataChanges: 'Ausstehende Änderungen',
    forms: 'Formulare',
    home: 'Startseite',
    manageRegistrations: 'Anmeldungen verwalten',
  },
  socialLogin: {
    tkAuth: 'Mit TK-Schulsoftware anmelden',
  },
  sort: {
    title: 'Sortieren',
    types: {
      asc: 'Aufsteigen (A-Z)',
      desc: 'Absteigend (Z-A)',
    },
  },
  teams: {
    title: 'Teams',
  },
  theme: {
    description: 'Ändere das Thema der Anwendung',
    title: 'Darstellung',
  },
  true: 'Wahr',
  //User

  users: {
    activate: 'Benutzer aktivieren',
    activateSuccess: 'Benutzer erfolgreich aktiviert',
    active: {
      description:
        'Hier sieht du alle aktiven Benutzer, die aktuell Zugriff auf das Elterndashboard haben.',
      empty: 'Keine aktiven Benutzer',
      title: 'Aktivierte Benutzer',
    },
    addOrganizationSuccess: 'Benutzer erfolgreich zur Organisation hinzugefügt',
    addRoleSuccess: 'Rolle erfolgreich zugewiesen',
    cannotRemoveRole: 'Die Rolle kann nicht entfernt werden',
    createSuccess: 'Benutzer erfolgreich erstellt',
    deactivate: 'Benutzer deaktivieren',
    deactivateSuccess: 'Benutzer erfolgreich deaktiviert',
    deleteSuccess: 'Benutzer erfolgreich gelöscht',
    description: 'Hier kannst du Benutzer einsehen und bearbeiten',
    edit: 'Benutzer bearbeiten',
    form: {
      defaultWorkspace: 'Standard-Arbeitsplatz',
      email: 'E-Mail',
      fullName: 'Name',
    },
    impersonate: 'Als Benutzer arbeiten',
    inactive: {
      description:
        'Hier siehst du alle inaktiven Benutzer, die keinen Zugriff auf das Elterndashboard haben.',
      empty: 'Keine inaktiven Benutzer',
      title: 'Deaktivierte Benutzer',
    },
    invite: {
      button: 'Neuen Benutzer einladen',
      create: 'Einladung erstellen',
      description: 'Lade einen neuen Benutzer zur Benutzung des Elterndashboards ein',
      form: {
        defaultWorkspace: 'Standard-Arbeitsplatz',
        email: 'E-Mail',
        fullName: 'Name',
        organizations: 'Organisationen',
        roles: 'Rollen',
        sendInviteEmail: 'Einladungs-Email senden',
        teams: 'Teams (nur für Entwickler)',
      },
      revoke: 'Einladung löschen',
      title: 'Neuen Benutzer einladen',
    },
    invitePending: 'Einladung ausstehend',
    invited: {
      description: 'Hier sieht du alle ausstehenden Einladungen',
      empty: 'Keine ausstehende Einladungen',
      title: 'Eingeladene Benutzer',
    },
    organizationRemoveSuccess: 'Benutzer erfolgreich von Organisation entfernt',
    organizationRequired: 'Der Benutzer muss mindestens einer Organisation angehören',
    removeRoleSuccess: 'Rolle erfolgreich entfernt',
    revokeInviteFailure: 'Einladung konnte nicht gelöscht werden',
    revokeInviteSuccess: 'Einladung erfolgreich gelöscht',
    search: 'Benutzer suchen',
    sections: {
      activate: {
        description: 'Der Benutzer wird aktiviert und erhält Zugriff auf die Anwendung',
        title: 'Benutzer aktivieren',
      },
      deactivate: {
        description:
          'Der Benutzer wird deaktiviert und kann sich nicht mehr anmelden, bis er wieder aktiviert wird',
        title: 'Benutzer deaktivieren',
      },
      delete: {
        description: 'Der Benutzer wird dauerhaft gelöscht',
        title: 'Benutzer löschen',
      },
      general: {
        description: 'Bearbeite generelle Informationen des Benutzers',
        title: 'Generelle Informationen',
      },
      organizations: {
        addOrganization: 'Organisation hinzufügen',
        description: 'Hier kannst du die Organisationen des Benutzers ansehen und bearbeiten',
        selectOrganization: 'Organisation auswählen',
        title: 'Organisationen',
      },
      roles: {
        addRole: 'Rolle hinzufügen',
        description: 'Hier kannst du die Rollen des Benutzers ansehen und bearbeiten',
        selectRole: 'Rolle auswählen',
        title: 'Rollen',
      },
    },
    title: 'Benutzer',
    updateSuccess: 'Benutzer erfolgreich aktualisiert',
  },
  workspaces: {
    admin: 'Administrator',
    app: 'Benutzer',
    developer: 'Entwickler',
    management: 'Verwaltung',
    select: 'Arbeitsbereich auswählen',
  },
};
