export default {
  title: 'Online-Anmeldungen',
  description: 'Verwalte die Online-Anmeldungen der Organisation',
  details: {
    description: 'Hier kannst du die Online-Anmeldung weiter bearbeiten oder löschen.',
  },
  visible: 'Sichtbar',
  invisible: 'Unsichtbar',
  published: 'Veröffentlicht',
  unpublished: 'Nicht veröffentlicht',
  search: 'Suchen',
  tabs: {
    general: 'Allgemein',
    forms: 'Formulare',
  },
  empty: {
    title: 'Keine Online-Anmeldungen',
    description:
      'Hier gibt es nichts zu sehen - in deiner Organisation wurden noch keine Online-Anmeldungen erstellt.',
  },
  create: {
    action: 'Online-Anmeldung erstellen',
    title: 'Online-Anmeldung erstellen',
    description: 'Erstelle eine neue Online-Anmeldung für deine Organisation',
  },
  form: {
    name: 'Name',
    description: 'Beschreibung',
    type: {
      label: 'Was möchten Sie erstellen?',
      options: {
        internal: 'Anmeldung für bereits registrierte Personen',
        external: 'Anmeldung für neue Personen',
      },
    },
  },
  notifications: {
    create: {
      success: 'Online-Anmeldung wurde erfolgreich erstellt',
      error: 'Fehler beim Erstellen der Online-Anmeldung. Bitte versuche es erneut.',
    },
    update: {
      success: 'Online-Anmeldung wurde erfolgreich aktualisiert',
      error: 'Fehler beim Aktualisieren der Online-Anmeldung. Bitte versuche es erneut.',
    },
    delete: {
      success: 'Online-Anmeldung wurde erfolgreich gelöscht',
      error: 'Fehler beim Löschen der Online-Anmeldung. Bitte versuche es erneut.',
    },
    updatePublication: {
      success: 'Veröffentlichungsstatus wurde erfolgreich aktualisiert',
      error: 'Fehler beim Aktualisieren des Veröffentlichungsstatus. Bitte versuche es erneut.',
    },
    visibility: {
      create: {
        success: 'Sichtbarkeit wurde erfolgreich hinzugefügt',
        error: 'Fehler beim Hinzufügen der Sichtbarkeit. Bitte versuche es erneut.',
      },
      delete: {
        success: 'Sichtbarkeit wurde erfolgreich gelöscht',
        error: 'Fehler beim Löschen der Sichtbarkeit. Bitte versuche es erneut.',
      },
    },
  },
  sections: {
    general: {
      title: 'Allgemein',
      description: 'Bearbeite allgemeine Informationen zur Online-Anmeldung',
    },
    visibility: {
      title: 'Sichtbarkeit',
      description: 'Stelle die Sichtbarkeit der Online-Anmeldung ein',
    },
    dangerZone: {
      title: 'Gefahrenbereich',
      description:
        'Hier kannst du die Online-Anmeldung löschen. Diese Aktion kann nicht rückgängig gemacht werden.',
    },
  },
  delete: {
    title: 'Online-Anmeldung löschen',
    description:
      'Bist du sicher, dass du diese Online-Anmeldung löschen möchtest? Alle Daten werden unwiderruflich gelöscht.',
    action: 'Online-Anmeldung löschen',
  },
  publish: {
    title: 'Online-Anmeldung veröffentlichen',
    description: 'Möchtest du diese Online-Anmeldung veröffentlichen? Sie wird für alle sichtbar.',
    action: 'Veröffentlichen',
  },
  unpublish: {
    title: 'Online-Anmeldung verbergen',
    description: 'Möchtest du diese Online-Anmeldung verbergen? Sie wird für alle unsichtbar.',
    action: 'Verbergen',
  },
  forms: {
    edit: 'Formular bearbeiten',
    sections: {
      custodianForm: {
        title: 'Formular für Sorgeberechtigte',
        description: 'Bearbeite das Formular für Sorgeberechtigte',
      },
      childForm: {
        title: 'Formular für Kinder',
        description: 'Bearbeite das Formular für Kinder',
      },
    },
    empty: {
      title: 'Noch kein Formular hinterlegt',
      description: 'Hier wurde noch kein Formular hinterlegt',
    },
  },
  visibility: {
    active: 'Aktiv',
    visibleFrom: 'Sichtbar ab',
    visibleUntil: 'Sichtbar bis',
    empty: {
      title: 'Keine Sichtbarkeit definiert',
      description: 'Deine Anmeldung ist zu jedem Zeitpunkt sichtbar',
    },
    create: {
      title: 'Sichtbarkeit hinzufügen',
      description: 'Füge eine neue Sichtbarkeit für deine Online-Anmeldung hinzu',
      action: 'Sichtbarkeit hinzufügen',
    },
    infinity: 'Keine Einschränkung',
  },
  errors: {
    notFound: 'Online-Anmeldung nicht gefunden',
  },
};
