export default {
  title: 'Benutzer',
  description: 'Verwalte alle Benutze des Systems.',
  search: 'Benutzer suchen',
  invite: {
    title: 'Benutzer einladen',
    description: 'Lade Benutzer ein, um sie diesem System hinzuzufügen.',
    action: 'Benutzer einladen',
    form: {
      name: 'Vollständiger Name',
      email: 'E-Mail',
    },
  },
  sections: {
    general: {
      title: 'Allgemeine Informationen',
      description: 'Bearbeite die allgemeinen Informationen des Benutzers.',
    },
    dangerZone: {
      title: "Gefahrenbereich",
      description: "Hier kannst du Aktionen durchführen, die nicht mehr rückgängig gemacht werden können. Sei vorsichtig!"
    }
  },
  deactivate: {
    title: "Benutzer deaktivieren",
    description: "Deaktiviere den Benutzer, um ihn am Zugriff auf das System zu hindern.",
  },
  delete: {
    title: "Benutzer löschen",
    description: "Lösche den Benutzer aus dem System. Diese Aktion kann nicht rückgängig gemacht werden.",
    action: "Benutzer löschen",
  },
  form: {
    fullName: 'Vollständiger Name',
    email: 'E-Mail',
    defaultWorkspace: 'Standard-Arbeitsbereich',
    administrative: 'Administrativ',
  },
  pendingInvites: {
    title: 'Ausstehende Einladungen',
  },
  dropdown: {
    edit: 'Benutzer bearbeiten',
    revokeInvite: 'Einladung widerrufen',
    impersonate: 'Benutzer übernehmen',
  },
};
