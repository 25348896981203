export default {
  title: 'Feedback',
  description: 'Hier siehst du eine Übersicht über dein Feedback.',
  open: 'Offenes Feedback',
  closed: 'Abgeschlossenes Feedback',
  details: {
    title: 'Feedback #{{id}}',
    description: 'Hier siehst du die Details zu deinem Feedback.',
  },
  content: 'Inhalt',
  comments: 'Kommentare',
};
