export default {
  addBlock: 'Block hinzufügen',
  title: 'Formular bearbeiten',
  actions: {
    save: 'Formular speichern',
  },
  blockTypeGroups: {
    input: 'Eingabe',
    text: 'Text',
    layout: 'Layout',
  },
  empty: {
    title: 'Keine Blöcke',
    description: 'Hier gibt es nichts zu sehen - dieses Formular enthält keine Blöcke.',
  },
  blockTypes: {
    INPUT_TEXT: 'Textfeld',
    INPUT_TEXTAREA: 'Textbereich',
    INPUT_EMAIL: 'E-Mail',
    INPUT_NUMBER: 'Nummer',
    INPUT_URL: 'URL',
    INPUT_FILE_UPLOAD: 'Datei-Upload',
    INPUT_DATE: 'Datum',
    INPUT_TIME: 'Zeit',
    INPUT_SELECTION: 'Auswahl',
    INPUT_MULTI_SELECTION: 'Mehrfachauswahl',
    INPUT_CHECKBOX: 'Checkbox',
    TEXT_HEADING_SMALL: 'Kleine Überschrift',
    TEXT_HEADING_MEDIUM: 'Mittlere Überschrift',
    TEXT_HEADING_LARGE: 'Große Überschrift',
    TEXT_PARAGRAPH: 'Textabschnitt',
    LAYOUT_SPACE: 'Leerzeile',
    LAYOUT_DIVIDER: 'Trennlinie',
    LAYOUT_PAGE: 'Seite',
    MEDIA_LOGO: 'Logo',
    MEDIA_IMAGE: 'Bild',
  },
  blockControls: {
    required: 'Erforderlich',
    delete: 'Löschen',
    duplicate: 'Duplizieren',
  },
  dataSourceMapping: {
    title: 'Datenquellen-Zuordnung',
    description: 'Bearbeite die Zuordnung dieses Formularfelds zu einer Datenquelle',
    entity: 'Entität',
    selectEntity: 'Entität auswählen',
    selectField: 'Attribut auswählen',
    field: 'Attribut',
    actions: {
      save: 'Zuordnung speichern',
      remove: 'Zuordnung entfernen',
    },
  },
  dataSourceConnection: {
    title: 'Datenquellen',
    description: 'Bearbeite die Datenquellen dieses Formularfelds',
    selectSource: 'Datenquelle auswählen',
    actions: {
      remove: "Datenquelle entfernen",
    },
    source: 'Datenquelle',
  },
  selectControl: {
    optionExists: 'Diese Option existiert bereits',
    addOption: 'Option hinzufügen',
    label: 'Anzeigename',
    value: 'Wert',
  },
  examples: {
    INPUT_TEXT: {
      label: 'Vorname',
      placeholder: 'John Doe',
    },
    INPUT_TEXTAREA: {
      label: 'Nachricht',
      placeholder: 'Geben Sie hier Ihre Nachricht ein',
    },
    INPUT_EMAIL: {
      label: 'E-Mail',
      placeholder: 'john@acme.sh',
    },
    INPUT_NUMBER: {
      label: 'Anzahl',
      placeholder: '123',
    },
    INPUT_URL: {
      label: 'URL',
      placeholder: 'https://acme.sh',
    },
    INPUT_FILE_UPLOAD: {
      label: 'Dateien anhängen',
      placeholder: 'Datei auswählen',
    },
    INPUT_DATE: {
      label: 'Geburtsdatum',
      placeholder: 'TT.MM.JJJJ',
    },
    INPUT_TIME: {
      label: 'Uhrzeit',
      placeholder: 'HH:MM',
    },
    INPUT_SELECTION: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    INPUT_MULTI_SELECTION: {
      label: 'Allergien',
      placeholder: 'Wähle eine oder mehrere Allergien aus',
    },
    INPUT_CHECKBOX: {
      label: 'Ich stimme den Nutzungsbedingungen zu',
      placeholder: 'Ja',
    },
    TEXT_HEADING_SMALL: {
      text: 'Bitte füllen Sie das Formular vollständig aus.',
    },
    TEXT_HEADING_MEDIUM: {
      text: 'Zahlungsdaten',
    },
    TEXT_HEADING_LARGE: {
      text: 'Zusammenfassung',
    },

    TEXT_PARAGRAPH: {
      text: 'Dies ist ein Absatz',
    },
    LAYOUT_SPACE: 'Leerzeile',
    LAYOUT_DIVIDER: 'Trennlinie',
    LAYOUT_PAGE: 'Seite',
    MEDIA_LOGO: 'Logo',
    MEDIA_IMAGE: 'Bild',
  },
};
