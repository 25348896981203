export default {
  navigation: {
    profile: 'Profil',
    feedback: 'Feedback',
    security: 'Sicherheit',
  },
  title: 'Profil',
  description: 'Hier kannst du dein Profil anpassen',
  sections: {
    personal: {
      title: 'Persönliche Daten',
      description: 'Passe deine persönlichen Daten an',
    },
    appearance: {
      title: 'Erscheinungsbild',
      description: 'Wähle dein bevorzugtes Erscheinungsbild',
    },
    uiPreferences: {
      title: 'Oberflächeneinstellungen',
      description: 'Passe die Oberflächeneinstellungen an',
    },
    logout: {
      title: 'Abmelden',
      description: 'Melde dich von deinem Konto ab',
    },
  },
  logout: 'Von diesem Gerät abmelden',
  preferColorPanels: 'Farbflächen anstatt Bildern bevorzugen',
  profile: {
    fullName: 'Vollständiger Name',
    email: 'E-Mail',
  },
  appearanceOptions: {
    light: 'Hell',
    dark: 'Dunkel',
  },
  notifications: {
    user: {
      success: 'Dein Profil wurde erfolgreich aktualisiert',
      error: 'Beim Aktualisieren deines Profils ist ein Fehler aufgetreten',
    },
    appearance: {
      success: 'Dein Erscheinungsbild wurde erfolgreich aktualisiert',
      error: 'Beim Aktualisieren deines Erscheinungsbilds ist ein Fehler aufgetreten',
    },
    uiPreferences: {
      success: 'Deine Oberflächeneinstellungen wurden erfolgreich aktualisiert',
      error: 'Beim Aktualisieren deiner Oberflächeneinstellungen ist ein Fehler aufgetreten',
    },
  },
};
