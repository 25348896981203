export default {
  title: 'Posteingang',
  description: 'Hier findest du alle Nachrichten, die du erhalten hast.',
  notifications: 'Benachrichtigungen',
  empty: {
    title: 'Keine Benachrichtigungen',
    description: 'Du hast aktuell keine ungelesenen Benachrichtigungen.',
  },
  contextMenu: {
    delete: 'Benachrichtigung löschen',
    markAsUnread: 'Als ungelesen markieren',
    markAsRead: 'Als gelesen markieren',
  },
  translations: {
    dataChangeRequestReceived: {
      subject: 'Änderungsantrag für {{personName}} erhalten',
      description: 'Wir haben ihren Änderungsantrag erhalten und werden ihn in Kürze bearbeiten.',
    },
  },
};
